import React, { useState, useRef, useEffect } from 'react';
import { TextField, FormControlLabel, Checkbox, Button, Grid, Typography, Table, TableBody, TableCell, TableHead, TableRow, Grid2, Box } from '@mui/material';
import QuoteSubmissionSuccess from 'Components/QuoteSubmitSuccess';

import { tableCellClasses } from '@mui/material/TableCell';
import RoomForm from "../Components/CustomerRoomForm";
import { styled } from '@mui/material/styles';
import { Alert, Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSubmitQuote } from '../hooks/quote';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNewSharp'; // Import the icon
import { useValidateDiscountCode } from 'hooks/quote';
import ReactGA from "react-ga4";
import { useLocation } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#235169",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function toFraction(decimal) {
  const tolerance = 1.0E-6;
  let h1 = 1, h2 = 0, k1 = 0, k2 = 1;
  let b = decimal;
  do {
    const a = Math.floor(b);
    const aux = h1; h1 = a * h1 + h2; h2 = aux;
    const auxk = k1; k1 = a * k1 + k2; k2 = auxk;
    b = 1 / (b - a);
  } while (Math.abs(decimal - h1 / k1) > decimal * tolerance);

  return `${h1}/${k1}`;
}


const CustomerCurtainForm = () => {
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState('');
  const [discountCode, setDiscountCode] = useState("");
  const [respondedDiscount, setRespondedDiscount] = useState({})
  const [includeTax, setIncludeTax] = useState(false);
  const [calculated, setCalculated] = useState(false);
  const [installation, setInstallation] = useState(false);
  const [delivery, setDelivery] = useState(false)
  const priceTableRef = useRef();
  const materialTableRef = useRef();
  const [alertOpen, setAlertOpen] = useState(false);
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false)
  const [quoteId, setQuoteId] = useState("")

  const { mutateAsync: submitQuoteForm, isLoading: isSubmitingQuoteForm } = useSubmitQuote();
  const { mutateAsync: validateDiscount, isLoading: isValidatingDiscount } = useValidateDiscountCode();
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  const [rooms, setRooms] = useState([{
    roomName: 'Living Room',
    curtainType: '',
    index: 1,
    fabric: '',
    height: '',
    width: '',
    unit: 'inches',
    installation: false,
    price: 0,
    widthFraction: 0,
    heightFraction: 0
  }]);

  const curtainTypes = {
    Zebra: [
      { name: 'Light-Filtering or Blackout, White', price: 90, },
      { name: 'Light-Filtering or Blackout, Silver, ', price: 90 },
      { name: 'Light-Filtering or Blackout, Grey', price: 90 },
    ],
    Shade: [
      { name: 'Light-Filtering or Blackout, White', price: 70 },
      { name: 'Light-Filtering or Blackout, Beige', price: 70 },
      { name: 'Light-Filtering or Blackout, Grey', price: 70 },
    ],
  };

  const updateRoom = (index, updatedRoom) => {
    const updatedRooms = [...rooms];
    updatedRooms[index] = updatedRoom;
    setRooms(updatedRooms);
  };

  const removeRoom = (index) => {
    const updatedRooms = rooms.filter((_, roomIndex) => roomIndex !== index);
    setRooms(updatedRooms);
  };

  const duplicateRoom = (index) => {
    let baseRoom = rooms[index]
    setCalculated(false)
    setRooms([...rooms, {
      roomName: baseRoom.roomName + `_copy`,
      curtainType: baseRoom.curtainType || "",
      fabric: baseRoom.fabric || '',
      height: baseRoom.height || '',
      width: baseRoom.width || '',
      unit: baseRoom.unit || 'inches',
      motorized: baseRoom.motorized || false,
      price: 0,
      widthFraction: baseRoom.widthFraction || 0,
      heightFraction: baseRoom.heightFraction || 0
    }]);
  };

  const calculatePriceForRoom = (room) => {
    const { unit, height, width, fabric, motorized, curtainType, widthFraction, heightFraction } = room;
    const heightInMeters = unit === 'cm' ? (parseFloat(height) + parseFloat(heightFraction)) / 100 : (parseFloat(height) + parseFloat(heightFraction)) * 0.0254;
    let widthInMeters = unit === 'cm' ? (parseFloat(width) + parseFloat(widthFraction)) / 100 : (parseFloat(width) + parseFloat(widthFraction)) * 0.0254;


    if (widthInMeters < 1) widthInMeters = 1;

    const area = heightInMeters * widthInMeters * (curtainType==="zebra"?2:1);
    const selectedFabric = curtainTypes[curtainType]?.find(f => f.name === fabric);
    const fabricPrice = selectedFabric ? selectedFabric.price : 0;

    let price = area * fabricPrice;
    let basePrice = price;
    if (motorized) price += 150;
    // if (delivery) price += 50;
    // if (installation) price += 30;

    return { price, basePrice };
  };

  const validateDiscountCode = async () => {
    let validateDiscountResponse = await validateDiscount(discountCode)
    if (validateDiscountResponse.status === "OK"){
      setRespondedDiscount(validateDiscountResponse.discount);
      
    }
  }

  const handleSubmit = () => {
    let invalidRooms = rooms.filter((room) => !room.width || !room.height || !room.fabric || !room.curtainType)
    if (invalidRooms.length > 0) {
      alert("Please fill all required fields(width, height, type, fabric) for each room.")
      return
    }
    const updatedRooms = rooms.map(room => ({
      ...room,
      ...calculatePriceForRoom(room)
    }));
    setRooms(updatedRooms);
    setCalculated(true);
  };


  const handleSubmitQuote = () => {
    let invalidRooms = rooms.filter((room) => !room.width || !room.height || !room.fabric || !room.curtainType)
    if (invalidRooms.length > 0) {
      alert("Please fill all required fields(width, height, type, fabric) for each room.")
      return
    }

    const updatedRooms = rooms.map(room => ({
      ...room,
      ...calculatePriceForRoom(room)
    }));
    setRooms(updatedRooms);
    setCalculated(true);

    submitQuoteForm({
      customerName,
      formJSON: rooms,
      email: customerEmail,
      includeTax,
      delivery,
      installation,
      customerPhoneNumber,
      discountCode,
      discount:respondedDiscount,
      overallPrice: totalPrice.toFixed(2)
    }, {
      onSuccess: (response) => {
        setAlertOpen(true); // Show the success alert
        setSubmitted(true);
        setQuoteId(response.quoteId)
        // console.log("response", response)
        // setTimeout(()=>navigate("/"),1000)

      }
    })
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false); // Close the alert when needed
  };

  const calculateTotalPrice = () => {
    let roomPrices = rooms.reduce((total, room) => total + parseFloat(room.price), 0);
    let deliveryPrice = delivery ? 50 : 0;
    let installationPrice = installation ? Math.max(50, rooms.length * 30) : 0;
    let totalPrice = roomPrices + deliveryPrice + installationPrice;
    return totalPrice.toFixed(2);
  };

  const calculateSubTotal = () => {
    let roomTotal = rooms.reduce(
      (sum, room) => sum + room.basePrice + (room.motorized ? 150 : 0),
      0
    );
    let deliveryFee = delivery ? 50 : 0;
    let installationFee = installation ? Math.max(50, rooms.length * 30) : 0;

    return roomTotal + deliveryFee + installationFee;
  };

  const calculateTotalWithDiscountAndTax = (subTotal) => {
    const discount = (respondedDiscount?.amount || 0) / 100;
    const tax = includeTax ? 0.13 : 0;
    return subTotal * (1 - discount) * (1 + tax);
  };

  const subTotal = calculateSubTotal();
  const totalPrice = calculateTotalWithDiscountAndTax(subTotal);



  return (
    submitted ?
      <QuoteSubmissionSuccess onReturnToWebsite={() => window.location.replace("https://shadesnow.ca")} quoteId={quoteId} />

      :
      <Grid style={{ paddingBottom: "100px", paddingTop: "50px" }} container spacing={2}>

        <Grid item xs={12} style={{ display: "flex" }}>
          <Typography variant="h5">Shades Now Instant Quote system</Typography>
        </Grid>

        {/* Customer Information */}
        <Grid item xs={12}>
          <TextField
            label="Name"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Email"
            value={customerEmail}
            onChange={(e) => setCustomerEmail(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="PhoneNumber"
            value={customerPhoneNumber}
            onChange={(e) => setCustomerPhoneNumber(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          {/* Room Forms */}
          <RoomForm
            rooms={rooms}
            updateRooms={setRooms}
            updateRoom={updateRoom}
            removeRoom={removeRoom}
            duplicateRoom={duplicateRoom}

          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={includeTax} onChange={(e) => setIncludeTax(e.target.checked)} />}
            label="Include Tax"
          />

          <FormControlLabel
            control={<Checkbox checked={delivery} onChange={(e) => {
              if (e.target.checked) setInstallation(false);
              setDelivery(e.target.checked)
            }} />}
            label="Delivery"
          />
          <FormControlLabel
            control={<Checkbox checked={installation} onChange={(e) => {
              if (e.target.checked) setDelivery(false);
              setInstallation(e.target.checked)
            }} />}
            label="Installation (Delivery Included)"
          />
        </Grid>

        {/* Calculate Button */}
        <Grid item xs={12}>
          <Button variant="contained" fullWidth sx={{ backgroundColor: "#235169" }} color="primary" onClick={handleSubmit}>
            Calculate Price
          </Button>
        </Grid>
        {calculated && <><Grid item xs={12}>
          <Grid container>

            {/* Summary Table */}
            <Grid item xs={12}>
              <Typography variant="h6">Price Breakdown</Typography>
              <Table ref={priceTableRef}>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Room</StyledTableCell>
                    <StyledTableCell>Base Price</StyledTableCell>
                    <StyledTableCell>Motorized (+$150)</StyledTableCell>
                    <StyledTableCell>Total Price</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {rooms.map((room, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{room.roomName}</StyledTableCell>
                      <StyledTableCell>${room.basePrice.toFixed(2)}</StyledTableCell>
                      <StyledTableCell>{room.motorized ? "+$150" : "$0"}</StyledTableCell>
                      <StyledTableCell>
                        ${(room.basePrice + (room.motorized ? 150 : 0)).toFixed(2)}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}

                  {delivery && (
                    <StyledTableRow>
                      <StyledTableCell colSpan={3}>Delivery</StyledTableCell>
                      <StyledTableCell>$50</StyledTableCell>
                    </StyledTableRow>
                  )}

                  {installation && (
                    <StyledTableRow>
                      <StyledTableCell colSpan={3}>
                        Installation (Delivery Included)
                      </StyledTableCell>
                      <StyledTableCell>
                        ${Math.max(50, rooms.length * 30).toFixed(2)}
                      </StyledTableCell>
                    </StyledTableRow>
                  )}

                  <StyledTableRow>
                    <StyledTableCell colSpan={3}>Sub-total Price</StyledTableCell>
                    <StyledTableCell>${subTotal.toFixed(2)}</StyledTableCell>
                  </StyledTableRow>

                  {respondedDiscount?.amount && (
                    <StyledTableRow>
                      <StyledTableCell colSpan={3}>
                        Discount ({respondedDiscount.amount}% for {respondedDiscount.discountCode})
                      </StyledTableCell>
                      <StyledTableCell>
                        -${(subTotal * (respondedDiscount.amount / 100)).toFixed(2)}
                      </StyledTableCell>
                    </StyledTableRow>
                  )}

                  {includeTax && (
                    <StyledTableRow>
                      <StyledTableCell colSpan={3}>HST (13%)</StyledTableCell>
                      <StyledTableCell>
                        ${(subTotal * 0.13).toFixed(2)}
                      </StyledTableCell>
                    </StyledTableRow>
                  )}

                  <StyledTableRow>
                    <StyledTableCell colSpan={3}>Total Price</StyledTableCell>
                    <StyledTableCell>${totalPrice.toFixed(2)}</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>

            </Grid>



          </Grid>
        </Grid>
          <Grid item xs={9}>
            <TextField
              label="DiscountCode"
              value={discountCode}
              onChange={(e) => setDiscountCode(e.target.value)}
              fullWidth
            />
          </Grid>
          {!respondedDiscount.amount &&
            <Grid item xs={3}>
              <Button variant="contained" fullWidth color="primary" onClick={validateDiscountCode}
                sx={{
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: "#235169",
                  height: "100%"
                  // alignItems: 'flex-start',
                }}
              >
                Apply Discount
              </Button>
            </Grid>}
          {respondedDiscount.amount &&
            <Grid item xs={3}>
              <Button variant="contained" fullWidth color="primary" onClick={() => {
                  setRespondedDiscount({})
                  setDiscountCode("")
              }}
                sx={{
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: "#235169",
                  height: "100%"
                  // alignItems: 'flex-start',
                }}
              >
                Remove Discount
              </Button>
            </Grid>}

          {respondedDiscount.amount && <Grid item xs={12}>
            <Typography>
              The discount code ({respondedDiscount.title}) activated! {`${respondedDiscount.amount}${respondedDiscount.type === "exact" ? "$" : "%"} discount will be applied on your qoute!`}
            </Typography>
          </Grid>}

          <Grid item xs={12}>
            <Button variant="contained" fullWidth sx={{ backgroundColor: "#235169" }} color="primary" onClick={handleSubmitQuote}>
              Submit Quote
            </Button>
          </Grid>
        </>



        }



        <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleAlertClose} severity="success" variant="filled">
            Quote submitted successfully!
          </Alert>
        </Snackbar>

      </Grid>

  );
};

export default CustomerCurtainForm;
