import React from 'react';
import { Box, Typography, Button, Stack } from '@mui/material';

const QuoteSubmissionSuccess = ({ quoteId, onReturnToWebsite, onSubmitAnotherQuote }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                textAlign: 'center',
                bgcolor: '#f5f5f5',
                padding: 4,
                borderRadius: 2,
                boxShadow: 3,
            }}
        >
            <Typography variant="h3" gutterBottom color="primary">
                Your quote has been submitted successfully!
            </Typography>
            <Typography variant="h4" color="textSecondary" gutterBottom>
                Here is your quote ID:
            </Typography>
            <Typography variant="h2" color="success" sx={{ mt: 2, fontWeight: 'bold' }}>
                {quoteId}
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ mt: 4, maxWidth: '600px' }}>
                Thank you for choosing ShadesNow! One of our team members will contact you 
                within 2-3 business days to discuss your quote and guide you through the 
                next steps. We look forward to helping you find the perfect window treatments 
                for your home.
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ mt: 2, maxWidth: '600px' }}>
                In the meantime, feel free to reach out to us using the contact details below:
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                <strong>Phone:</strong> +1 (844) 698-4643 <br />
                <strong>Email:</strong> info@shadesnow.ca <br />
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                You can also visit our <a href="https://shadesnow.ca/contact" target="_blank" rel="noopener noreferrer" style={{ color: '#1976d2' }}>Contact Us</a> page for more options.
            </Typography>
            <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onReturnToWebsite}
                >
                    Return to Homepage
                </Button>
                
            </Stack>
        </Box>
    );
};

export default QuoteSubmissionSuccess;
