import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import CurtainForm from './Pages/SubmitCurtainQuote';
import CustomerCurtainForm from './Pages/CustomerSubmitCurtainQuote';
import ShowCurtains from './Pages/ShowAllCurtainQuotes';
import ShowCurtainQuote from './Pages/ShowCurtainQuote';
import ShowCurtainMaterial from './Pages/ShowCurtainMaterial';
import ShowCurtainFabricCuts from "./Pages/ShowCurtainFabricCuts";
import PrintCurtainQuote from './Pages/PrintCurtainQuote';
import EditCurtainQuote from './Pages/EditCurtainQuote';
import LoginPage from './Pages/LoginPage';
import { Container } from '@mui/material';
import Cookies from 'universal-cookie';
import usePageTracking from 'Components/PageTracking';

import ReactGA from "react-ga4";
ReactGA.initialize("G-ZB43V4SYS9");

const cookies = new Cookies(null, { path: '/' });

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(cookies.get('salam'));

  return (
    <Router>
      <Container>
        <Routes>
          {/* If user is not logged in, redirect to the login page */}
          {!isLoggedIn ? (
            <>
            <Route path="/cquote" element={<CustomerCurtainForm />} />
            <Route path="*" element={<LoginPage setIsLoggedIn={setIsLoggedIn} remember={(state) => { if (state) cookies.set('salam', true); }} />} />
            </>
          ) : (
            <>
              {/* Logged-in routes */}
              <Route path="/" element={<Navigate to="/show-all-quotes" />} />
              <Route path="/fabric" element={<ShowCurtainFabricCuts/>} />
              <Route path="/show-all-quotes" element={<ShowCurtains />} />
              <Route path="/show-material/:quoteId" element={<ShowCurtainMaterial />} />
              <Route path="/show-quote/:quoteId" element={<ShowCurtainQuote />} />
              <Route path="/print-quote/:quoteId" element={<PrintCurtainQuote />} />
              <Route path="/edit-quote/:quoteId" element={<EditCurtainQuote />} />
              <Route path="/submit-quote" element={<CurtainForm />} />
              
            </>
          )}
        </Routes>
      </Container>
    </Router>
  );
}

export default App;
